import { template as template_948c7e0b2f864de4910c590686fc93d6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_948c7e0b2f864de4910c590686fc93d6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
