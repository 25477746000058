import { template as template_e2d4102e9a544ccbab12a6b74616943d } from "@ember/template-compiler";
const FKControlMenuContainer = template_e2d4102e9a544ccbab12a6b74616943d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
