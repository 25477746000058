import { template as template_ff775f2f705f4683a7121293b7866b1a } from "@ember/template-compiler";
const WelcomeHeader = template_ff775f2f705f4683a7121293b7866b1a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
